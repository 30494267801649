import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import dataProvider from '../../dataProvider';
import authProvider from '../../authProvider';
import PortfolioList from "../../resources/Portfolio/PortfolioList";
import PortfolioEdit from "../../resources/Portfolio/PortfolioEdit";
import PortfolioCreate from "../../resources/Portfolio/PortfolioCreate";

const AdminPanel = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider} basename="/admin">
    <Resource name="portfolio" list={PortfolioList} edit={PortfolioEdit} create={PortfolioCreate} />
  </Admin>
);

export default AdminPanel;
