import React, {Suspense} from "react";
import { BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import AdminPanel from "./pages/Admin/Admin";


const HomePage = React.lazy(() => import('./pages/Home/Index'));
const Portfolio = React.lazy(() => import('./pages/Portfolio/index'));
const Contact = React.lazy(() => import('./pages/Contact/index'));
const Carieer = React.lazy(() => import('./pages/Carieer/index'));
const AboutUs = React.lazy(() => import('./pages/AboutUs/index'));
const Advertising = React.lazy(() => import('./pages/Services/advertising/index'));
const WebDevelopment = React.lazy(() => import('./pages/Services/web/index'));
const DigitalMarketing = React.lazy(() => import('./pages/Services/digital-marketing/index'));
const UxUi = React.lazy(() => import('./pages/Services/ui-ux/index'));
const Seo = React.lazy(() => import('./pages/Services/seo/index'));
const Grafika = React.lazy(() => import('./pages/Services/grafika/index'));


function App() {
  return (
    <Suspense fallback={<p> </p>}>
    <BrowserRouter>
    <Suspense fallback={<p> </p>} />
      <Routes>
          <Route index element={< HomePage/>} />
          <Route exact path="/portfolio" element={< Portfolio/>} />
          <Route exact path="/kontakt" element={<Contact />} />
          <Route exact path="/kariera" element={<Carieer />} />
          <Route exact path="/o-nas" element={<AboutUs />} />
          <Route exact path="/web-development" element={<WebDevelopment />} />
          <Route exact path="/social-media-marketing" element={<DigitalMarketing/>} />
          <Route exact path="/reklamy" element={<Advertising/>} />
          <Route exact path="/ux-ui" element={<UxUi/>} />
          <Route exact path="/seo" element={ <Seo/>} />
          <Route exact path="/grafika" element={ <Grafika/>} />
          <Route exact path="/admin/*" element={<AdminPanel />} /> {/* Add this line */}
          <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
    </BrowserRouter>
    </Suspense>

  );
}

export default App;
