import React from 'react';
import {List, Datagrid, TextField, EditButton, ImageField} from 'react-admin';

const PortfolioList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="href" />
            <ImageField source="image" />
            <TextField source="description" />
            <EditButton />
        </Datagrid>
    </List>
);

export default PortfolioList;
