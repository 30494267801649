// src/Resources/UserEdit.js
import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput, Create, ImageInput, ImageField
} from 'react-admin';

const PortfolioCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
          <TextInput source="type" />
          <TextInput source="href" />

          <TextInput source="title" />
          <TextInput source="description" />
          <ImageInput source="image" label="Image" accept="image/*">
              <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
    </Create>
);

export default PortfolioCreate;