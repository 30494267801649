import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput
} from 'react-admin';

const PortfolioEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
          <TextInput source="type" />
          <TextInput source="href" />
          <TextInput source="image" />
          <TextInput source="title" />
          <TextInput source="description" />
        </SimpleForm>
    </Edit>
);

export default PortfolioEdit;
